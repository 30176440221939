.nav-link {
	text-decoration: none;
	&[class*=bg-] {
		transition: .1s;
		&:hover,
		&:focus {
			position: relative;
			z-index: 1;
		}
	}
	@each $color, $value in $theme-colors {
		&.bg-#{$color} {
			position: relative;
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				box-sizing: content-box;
				border: 0 solid darken($value,10%);
				transition: .1s;
			}
			&:hover,
			&:focus {
				&::before {
					top: -.5rem;
					left: -.5rem;
					right: -.5rem;
					bottom: -.5rem;
					border-width: .75rem;
				}
			}
		}
	}
}


.nav-pills {
	.nav-link {
		border-radius: 0;
	}
}

.nav-buttons {
	margin-left: -($spacer/2);
	margin-right: -($spacer/2);
	.nav-item {
		padding-left: $spacer/2;
		padding-right: $spacer/2;
	}
	.nav-link {
		@extend .btn;
		@extend .btn-primary;
		display: block;
	}
}

.nav-primary-mobile {
	@include media-breakpoint-down(md) {
		color: $body-bg;
		background: $primary;
		.nav-item {
			&:first-child {
				border-top: 1px solid rgba($body-bg, .2);
			}
		}


		.nav-link {
			display: flex;
			align-items: center;
			color: $body-bg;
			padding-top: .5rem;
			padding-bottom: .5rem;
			border-bottom: 1px solid rgba($body-bg, .2);
			&:focus,
			&:hover,
			&.active {
				background: rgba($body-bg,.2);
			}
			.icon {
				margin-right: .5rem;
			}
		}

		.dropdown {
			&-menu {
				position: static!important;
				transform: none!important;
				background: none;
				border: none;
				float: none;
			}
			&-toggle {
				&::after {
					content: $icon-arrow-long-down;
					margin-left: auto;
				}
			}
			&.show {
				background: rgba($black, .1);
				.dropdown-toggle {
					&::after {
						content: $icon-arrow-long-up;
					}
				}
			}
			&-item {
				padding-top: .4rem; 
				padding-bottom: .4rem;
				color: $body-bg;
				&:focus,
				&:hover {
					background: rgba($body-bg,.2);
				}
			}
		}
	}
}

.navbar-top {
	padding: .8rem 0;
	margin-bottom: -1px;
	border-bottom: 1px solid rgba($body-bg, .2);
	.nav-item {
		margin: 0 1rem;
		@include media-breakpoint-up(xxl) {
			margin: 0 2.35rem;
		}
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	.nav:last-child {
		.nav-item {
			@include media-breakpoint-up(xxl) {
				margin: 0 1.55rem;
			}
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
	.nav-link {
		color: $body-bg;
		font-size: $font-size-sm;
		font-weight: $font-weight-bold;
		padding: 0;
		display: flex;
		align-items: center;
		&:hover {
			text-decoration: underline;
		}
		.icon {
			font-size: 140%;
			margin-right: .5em;
			&::before {
				display: inline-block;
			}
		}
	}
}


.navbar-main {
	display: flex;
	align-items: center;
	position: relative;
	z-index: $zindex-fixed+1;
	.nav-link * {
		display: inline-block;
		vertical-align: middle;
	}
}


.nav-main {
	&>.nav-item {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 5.2rem;
		height: 5.2rem;
		@include media-breakpoint-up(sm) {
			width: 7rem;
			height: 7rem;
		}
		@include media-breakpoint-up(md) {
			width: 8.4rem;
			height: 8.4rem;
		}
		&.nav-logo {
			border: none;
			background: rgba($white,.2);
			.nav-link {
				padding-top: 1rem;
				img {
					height: 6.5rem;
				}
				&::after {
					display: none;
				}
			}
			&.nav-caret {
				&::before {
					border-left-color: rgba($white,.2);
					@include media-breakpoint-down(md) {
						z-index: 9999;
					}
				}
				&::after {
					@include media-breakpoint-down(md) {
						z-index: 9999;
					}
				}
			}
		}
		&.nav-caret {
			& + .nav-item {
				clip-path: polygon(-1% 0%, 120% 0%, 120% 100%, -1% 100%, -1% 55%, 4% 50%, -1% 45%);
			}
		}
		&.nav-caret {
			&::before,
			&::after {
				content: '';
				position: absolute;
				top: 50%;
				left: 100%;
				border-top: .4rem solid transparent;
				border-bottom: .4rem solid transparent;
				border-left: .4rem solid transparent;
				margin: -.4rem 0 0;
				transition: .1s;
			}
			&:not(.nav-logo) {
				&::before,
				&::after {
					z-index: 9999;
				}
			}
			&:hover {
				&::after {
					border-left-color: rgba($black,.2);
				}
			}
		}
		@each $color, $value in $theme-colors {
			&.nav-caret.bg-#{$color} {
				&::before {
					border-left-color: $value;
				}
			}
		}
		&>.nav-link {
			position: relative;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			line-height: 1;
			padding: 1rem;
			font-size: $font-size-base*1.5;
			color: $body-bg;
			transition: .1s;
			&::before {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				border: 0;
				border-top: 2px solid transparent;
			}
			&:hover {
				background: rgba($black,.2);
			}
			&>small {
				@extend .font-weight-bold;
				display: block;
				font-size: $font-size-base;
			}
		}
		&>.nav {
			display: block;
			height: 100%;
			&>.nav-item {
				height: 50%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: center;
				&:nth-child(1) {
					clip-path: polygon(0 0, calc(100% + 1px) 0, calc(100% + 1px) calc(100% + 1px), .4rem calc(100% + 1px), 0 calc(100% - .4rem));
				}
				&:nth-child(2) {
					clip-path: polygon(.4rem 0,calc(100% + 1px) 0,calc(100% + 1px) 100%,0 calc(100% + 1px),0 .4rem);
				}
				&>.nav-link {
					display: flex;
					flex-direction: column;
					justify-content: center;
					color: $body-bg;
					text-align: center;
					height: 100%;
					&:hover {
						background: rgba($black,.2);
					}
				}
			}
		}
		&.dropdown {
			position: static;
			&>.nav-link {
				@extend .font-weight-bold;
				font-size: $font-size-base;
				&::after {
					margin: .3rem 0 0;
					font-family: 'icons';
					content: '\e90e';
					width: 100%;
				}
			}
		}
		&.no-dropdown {
			&>.nav-link{
				@extend .font-weight-bold;
				font-size: $font-size-base;
			}
		}
		&.show {
			&>.nav-link {
				z-index: ($zindex-fixed)-1;
				background: inherit;
				&::before {
					top: -2px;
					border-color: $body-bg;
				}
			}
			&>.nav-link {
				top: 2px;
			}
		}
		.dropdown-menu {
			transform: none !important;
			top: 100% !important;
			border: 0;
			border-top: 2px solid $body-bg;
			width: 100%;
			padding: 2em;
			background: inherit;
			margin: 0;
			a {
				color: $body-bg;
			}
			.nav {
				margin: 1.5em 0;
				flex-direction: column;
			}
			.nav-item {
				overflow: hidden;
			}
			.nav-link {
				float: left;
				width: 100%;
				padding: .4em 0 .4rem 1rem;
				border: 0;
				font-size: $font-size-base*.88;
				line-height: $headings-line-height;
				&::before {
					display: inline-block;
					width: 1rem;
					margin: 0 0 0 -1rem;
					font-family: 'icons';
					content: $icon-arrow-right;
					font-size: $font-size-base*.5;
					opacity: .5;
				}
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}


.nav-lang {
	text-transform: uppercase;
	&>.btn,
	&>.btn:focus,
	&>.btn:hover,
	&.show>.btn {
		background: none!important;
		border: none!important;
		padding: 0 .5rem;
		box-shadow: none!important;
	}
	.dropdown-toggle {
		font-weight: $font-weight-bold;
		&::after {
			content: '\e906';
		}
	}
}



.nav-media {
	.nav-link {
		display: flex;
		align-items: center;
		&:hover {
			text-decoration: underline;
		}
		.icon {
			display: inline-block;
			text-decoration: none;
			line-height: 0;
			height: 1.9rem;
			width: 1.9rem;
			padding: .95rem 0;
			font-size: 1rem;
			text-align: center;
			margin-right: .5em;
			border-radius: 50%;
			box-shadow: 0 0 0 1px $link-color;
			&::before {
				display: inline-block;
				line-height: 0;
			}
		}
	}
}


.nav-icon {
	.nav-link {
		.icon {
			display: inline-block;
			vertical-align: middle;
			font-size: 1.25em;
			padding-right: .5em;
		}
		&:hover {
			text-decoration: underline;
		}
	}
}





.nav-category {
	@include media-breakpoint-up(xl) {
		position: relative;
		top: 6rem;
		margin-top: -6rem;
	}
	.nav {
		@include media-breakpoint-up(xxl) {
			margin-right: ($grid-gutter-width / -2);
			margin-left: ($grid-gutter-width / -2);
		}
		@include media-breakpoint-down(xs) {
			margin-right: ($grid-gutter-width / -2);
			margin-left: ($grid-gutter-width / -2);
		}
	}
	.nav-item {
		display: flex;
		padding: 0;
		@extend .col-12;
		@extend .col-sm-6;
		@extend .col-lg-4;
		@extend .col-xl-2;
	}
	.nav-link {
		font-weight: $font-weight-bold;
		color: $body-bg;
		width: 100%;
		max-width: 100%;
		padding: 1em 1.5em;
		@include media-breakpoint-up(xxl) {
			padding: 3em 2.25em 3.5em;
		}
		.icon {
			display: block;
			font-size: $font-size-base * 1.75;
		}
	}
	&--small {
		.nav {
			&-link {
				position: relative;
				color: $body-bg;
				text-align: center;
				min-width: 3.75rem;
				padding: 1rem .75rem;
			}
		}
	}
}

.nav-footer {
	.nav-link {
		text-decoration: underline;
		text-decoration-color: rgba($primary, .5);
		@include media-breakpoint-down(xs) {
			display: inline-block;
			padding-left: 0;
			padding-right: 0;
		}
		&:hover {
			text-decoration: none;
			color: $link-color;
		}
	}
}


.nav-dark {
	.nav-link {
		color: $body-bg;
		.icon {
			border-color: $body-bg;
			box-shadow: 0 0 0 1px $body-bg;
		}
	}
}



.nav-logos {
	margin: 0 -$spacer;
	display: block;
	.nav-item {
		margin: $spacer;
		display: inline-block;
		vertical-align: middle;
	}
	.nav-link {
		padding: 0;
		&>img {
			height: 3rem;
			width: auto!important;
		}
	}
}


.nav-tabs {
	.nav-item {
		padding-top: 2px;
		position: relative;
		&.show {
			.nav-link {
				color: $body-bg;
				background: $primary;
			}
		}
	}
	.nav-link {
		font-weight: $font-weight-bold;
		color: $body-bg;
		padding: $nav-link-padding-y*2 $nav-link-padding-x*2;
		background: $primary;
		transition: .1s;
		box-shadow: 0 0 0 1px rgba($white,.5);
		&.disabled {
			color: rgba($body-bg,.25);
			background: $primary;
		}
		&.active {
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				content: '';
				background: $primary;
				height: 2px;
			}

			&:hover {
				color: $primary;
				background: $white;
			}
		}
		&:hover {
			background: darken($primary,5%);
		}
	}
}


.nav-filter-wrapper {
	overflow: auto;
	width: 100%;
	border-top: 1px solid $gray-400;
	.nav-filter {
		margin-bottom: .6rem;
	}
}
.nav-filter {
	display: block;
	display: flex;
	flex-wrap: nowrap;
	.nav-item {
		display: flex;
		position: relative;
		@include media-breakpoint-down(lg) {
			min-width: 9.5rem;
		}
		.badge {
			position: absolute;
			top: 0;
			right: 0;
		}
	}
	.nav-link {
		position: relative;
		width: 100%;
		text-align: left;
		padding: .75rem 1rem .75rem 2.8rem;
		line-height: 1.1;
		color: $body-color;
		border-bottom: 1px solid transparent;
		&:hover {
			background: $gray-100;
		}
		&:not(.nav-more)::before {
			font-family: 'icons';
			content: '';
			position: absolute;
			left: .7rem;
			top: .7rem;
			width: 1.3rem;
			height: 1.3rem;
			line-height: 1.3rem;
			color: $primary;
			background: $body-bg;
			font-size: 1rem;
			text-align: center;
			box-shadow: 0 0 0 1px $gray-400;
		}
		&.active {
			color: $body-bg;
			background: $primary;
			z-index: 1;
			border-color: transparent;
			&::before {
				content: $icon-check;
				box-shadow: 0 0 0 1px $body-bg;
			}
		}
		&.nav-more {
			padding-left: 1rem;
			text-align: center;
		}
	}
	&:not(.flex-column) {
		border-left: 1px solid $gray-400;
		.nav-item {
			border-bottom: none;
		}
		.nav-link {
			border-right: 1px solid $gray-400;
			border-bottom: 1px solid $gray-400;
			&.active {
				margin: -1px -1px 0;
				&::after {
					content: '';
					position: absolute;
					left: .75rem;
					top: 100%;
					border-left: .6rem solid transparent;
					border-right: .6rem solid transparent;
					border-top: .6rem solid $primary;
				}
			}
		}
	}
}

.nav-filter-simple {
	display: block;
	display: flex;
	flex-wrap: nowrap;
	.nav-item {
		display: flex;
		flex-grow:1;
		position: relative;
		@include media-breakpoint-down(lg) {
			min-width: 9.5rem;
		}
		.badge {
			position: absolute;
			top: 0;
			right: 0;
		}
	}
	.nav-link {
		position: relative;
		width: 100%;
		text-align: left;
		padding: .75rem 1rem .75rem .75rem;
		line-height: 1.1;
		border-bottom: 1px solid transparent;
		color: $gray-600;
		background: rgba($gray-100,0.2);
		min-height: 3em;

		&:hover {
			background: $gray-100;
			color: darken($primary, 5%);;
		}
		&.active {
			color: $body-bg;
			background: $primary;
			z-index: 1;
			border-color: transparent;

			&:hover{
				color: $body-bg;
				background: darken($primary, 5%);
			}
		}

		.description{
			margin-top: 0.4em;
			font-size: 90%;
		}
	}

    &.all-nav-items-active{
        .nav-link{
			border-color: $gray-400;
			&, &.active{
				color: $primary;
				background: $body-bg;

				&:hover {
					background: $gray-100;
					color: darken($primary, 5%);
				}
				&.current{
					background: $gray-100;
				}
			}
		}
    }


	&:not(.flex-column) {
		border-left: 1px solid $gray-400;
		.nav-item {
			border-bottom: none;
		}
		.nav-link {
			border-right: 1px solid $gray-400;
			border-bottom: 1px solid $gray-400;
		}
	}
}


.nav-calendar-wrapper {
	position: relative;
	overflow: hidden;
	max-width: 1920px;
	margin: auto;
	@include media-breakpoint-up(xl) {
		padding: 0 8.8rem;
	}
}
.nav-calendar {
	position: absolute;
	top: 0;
	height: 100%;
	display: none;
	flex-direction: column;
	justify-content: space-between;
	padding: 4px 0 0;
	width: 7.65rem;
	&.nav-calendar-prev {
		left: 0;
	}
	&.nav-calendar-next {
		right: 0;
	}
	.nav-link {
		position: absolute;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		text-align: center;
		top: 12rem;
		left: 0;
		right: 0;
		bottom: 0;
		color: $body-bg;
		background: $primary;
		transition: .1s;
		padding: 2rem .5rem;
		font-size: $font-size-base * 1.1;
		&:hover {
			background: darken($primary, 5%);
		}
	}
	.nav-link-toggle {
		background: $success;
		top: 0;
		height: 11.5rem;
		margin-bottom: .5rem;
		&:hover {
			background: darken($success, 5%);
			&::before {
				border-right-color: darken($success, 5%);
			}
		}
		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 50%;
		}
		&::before {
			right: 100%;
			border-top: 1.1rem solid transparent;
			border-bottom: 1.1rem solid transparent;
			border-right: 1rem solid $success;
			margin: -1.1rem 0 0;
			transition: .2s;
		}
		.rotate {
			text-align: center;
			position: absolute;
			left: 50%;
			top: 50%;
			width: 11rem;
			height: 7.6rem;
			padding: 0 1rem;
			line-height: 1.2;
			margin: -3.7rem 0 0 -5.5rem;
			transform: rotate(-90deg);
		}
		.icon {
			display: block;
			line-height: 1;
			margin-bottom: 1rem;
			margin-left: 4px;
		}
	}
}


.nav-page {
	.nav-item {
		position: relative;
		display: flex;
		flex: 1;
		margin-bottom: 1px;
		font-size: $small-font-size;
		font-weight: $font-weight-bold;
		line-height: 1.2;
		&:last-child {
			.nav-link {
				width: 100%;
			}
		}
		&:hover {
			z-index: 1;
		}
	}
	.nav-link {
		display: flex;
		align-items: center;
		width: calc(100% - 1px);
		padding: 1.5rem 1.5rem;
		background: $gray-100;
		transition: .1s;
		&:hover,
		&:focus,
		&.active {
			color: $white;
			background: $primary;
		}
		&:hover,
		&:focus {
			box-shadow: 0 0 0 .5rem $primary;
		}
	}
}

.nav-header {
	position: relative;
	z-index: 1;
	@include media-breakpoint-up(sm) {
		top: 2.5rem;
		margin-top: -2.5rem;
	}
	.nav-item {
		width: 100%;
		@include media-breakpoint-up(sm) {
			width: 50%;
		}
		@include media-breakpoint-up(md) {
			width: auto;
			flex-grow: 1;
			flex-basis: 0;
		}
 	}
	.nav-link {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0!important;
		padding: 0 1rem;
		height: 5rem;
		line-height: 1;
		&[class*="bg-"] {
			color: $white;
		}
	}
}

.nav-tabs-light-overflow {
	overflow: hidden;
	height: 5rem;
}

.nav-tabs-light-wrap {
	overflow: auto;
	height: 7rem;
}

.nav-tabs-light {

	.nav-item {
		margin-bottom: -2px;
	}

	.nav-link {
		white-space: nowrap;
		padding-top: 1.4rem;
		padding-bottom: 1.4rem;
		padding-left: .5rem;
		padding-right: .5rem;
		font-weight: $font-weight-bold;

		border-bottom: 2px solid rgba($primary,.2);

		.bg-dark & {
			color: $white;
			border-color: rgba($white,.2);
		}

		&:hover {
			border-color: rgba($primary,.5);

			.bg-dark & {
				border-color: rgba($white,.5);
			}
		}
		&.active {
			border-color: $primary;

			.bg-dark & {
				border-color: $white;
			}
		}
	}

	.nav-more {
		.nav-link {
			text-align: right;
			border-color: transparent;
			text-decoration: underline;

			&:hover {
				border-color: transparent;
				text-decoration: none;
			}
		}
	}
}



.nav-border {
	&.row {
		margin-left: -.5rem;
		margin-right: -.5rem;
		&>[class*=col-] {
			padding-left: .5rem;
			padding-right: .5rem;
		}
	}
	.nav-item {
		display: flex;
		width: 100%;
		margin-bottom: .75rem;
	}
	.nav-link {
		display: flex;
		align-items: center;
		line-height: 1.2;
		flex: 1;
		border: 2px solid rgba($primary,.25);
		padding: .5rem 1.6rem;
		font-weight: $font-weight-bold;
		min-height: 4.85rem;

		&:hover,
		&.active {
			color: $primary;
			border-color: $primary;
		}
	}
}


.book-item {
	display: block;
	border: .5rem solid $white;
	transition: .1s;
	box-shadow: 0 0 $spacer*2 rgba(#000,.5);
	&:hover {
		box-shadow: 0 0 $spacer*3 $primary;
	}
}



.box-link {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	text-decoration: none;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: .1s;
		background: $primary;
	}

	&:hover {

		&::before {
			top: -.5rem;
			left: -.5rem;
			right: -.5rem;
			bottom: -.5rem;
			border-width: .5rem;
		}
		
	}

	img {
		position: relative;
	}

	.box-link-content {
		position: relative;
		display: flex;
		align-items: center;
		padding: 1rem 2.4rem;
		font-size: $font-size-base * 1.13;
		line-height: 1.4;

		&::after {
			font-family: 'icons';
			content: "\e90c";
			padding-left: 2rem;
		}

		p {
			margin: 0;
		}
	}
}

@each $color, $value in $theme-colors {
	.box-link.bg-#{$color}::before {
		background: darken($value,10%);
	}
}