.clear-left{
  clear: left;
}

.clear-right{
  clear: right;
}

.clear-both{
  clear: both;
}

.media-box{
  position: relative;
  img, embed, video, audio{
    max-width: 100%;
    height: auto;
  }
}