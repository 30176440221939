.bg-cover {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.bg-contain {
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.bg-net {
	margin: auto;
	background-repeat: no-repeat;
	background-position: top;
	background-size: 576px auto;
	background-image: url($bg-net);
	@if ($bg-net-svg){
		background-image: url($bg-net-svg), linear-gradient(transparent,transparent);
	}

	@include media-breakpoint-up(sm) {
		background-size: 768px auto;
	}

	@include media-breakpoint-up(md) {
		background-size: 992px auto;
	}

	@include media-breakpoint-up(lg) {
		background-size: 1242px auto;
	}

	@include media-breakpoint-up(xl) {
		background-size: 1540px auto;
	}

	@include media-breakpoint-up(xxl) {
		background-size: 100% auto;
	}


}

.bg-net-offset {
	
	@include media-breakpoint-up(sm) {
		background-position: center -127px;
	}

	@include media-breakpoint-up(md) {
		background-position: center -166px;
	}

	@include media-breakpoint-up(lg) {
		background-position: center -207px;
	}

	@include media-breakpoint-up(xl) {
		background-position: center -256px;
	}

	@include media-breakpoint-up(xxl) {
		background-position: center -320px;
	}
}


.bg-net-home {
	@include media-breakpoint-down(xs) {
		background: none;
	}

	@include media-breakpoint-up(sm) {
		background-position: center;
	}

	@include media-breakpoint-up(md) {
		background-position: center 685px;
	}

	@include media-breakpoint-up(lg) {
		background-position: center 793px;
	}

	@include media-breakpoint-up(xl) {
		background-position: center 393px;
	}

	@include media-breakpoint-up(xxl) {
		background-image: linear-gradient($white,transparent), linear-gradient(transparent,$white), url($bg-net);
		@if ($bg-net-svg){
			background-image: linear-gradient($white,transparent), linear-gradient(transparent,$white), url($bg-net-svg);
		}
		background-position: top,bottom,bottom;
		background-repeat: repeat-x, repeat-x, no-repeat;
		background-size: 100% 50%, 100% 50%, 100% auto;
	}
}

.bg-net-bottom {
	background-position: center  calc(100% + 287px);
	@include media-breakpoint-up(sm) {
		background-position: center  calc(100% + 383px);
	}

	@include media-breakpoint-up(md) {
		background-position: center  calc(100% + 495px);
	}

	@include media-breakpoint-up(lg) {
		background-position: center calc(100% + 620px);
	}

	@include media-breakpoint-up(xl) {
		background-position: center calc(100% + 770px);
	}

	@include media-breakpoint-up(xxl) {
		background-image: linear-gradient($white,transparent), url($bg-net);
		@if($bg-net-svg){
			background-image: linear-gradient($white,transparent), url($bg-net-svg);
		}
		background-position: top,top;
		background-repeat: repeat-x, no-repeat;
		background-size: 100% 50%, 100% auto;
	}
}

@mixin bg-net-inv($text-color, $nav-link-color, $highlight-color, $background-color, $background-image, $background-image-svg, $background-top-image, $background-top-image-svg, $background-bottom-image, $background-bottom-image-svg){
	background-repeat: no-repeat;
	background-position: top;
	background-size: 200% auto;
	background-image: url($background-image);
	@if($background-image-svg){
		background-image: url($background-image-svg), linear-gradient(transparent, transparent);
	}
	background-color: $background-color !important;
	color: $text-color;
	@include media-breakpoint-up(md) {
		background-size: 100% auto;
	}
	&.bg-limited{
		max-width: 1920px;
		margin: auto;
	}
	&.bg-cover {
		background-size: cover;
	}
	&.bg-top {
		background-image: url($background-top-image);
		@if($background-top-image-svg){
			background-image: url($background-top-image-svg), linear-gradient(transparent, transparent);
		}
	}
	&.bg-bottom {
		background-image: url($background-bottom-image);
		@if($background-bottom-image-svg){
			background-image: url($background-bottom-image-svg), linear-gradient(transparent, transparent);
		}
	}
	.nav-border{
		.nav-link{
			color: $nav-link-color;
			border-color: rgba($nav-link-color,.1);
			&:hover,
			&.active{
				color: $highlight-color;
				border-color: $highlight-color;
			}
		}
	}
	a,
	a:hover{
		color: $text-color;
	}
	.text-highlight-color,
	a.text-highlight-color{
		color: $highlight-color !important;
	}
}

.bg-net-inv{
	@include bg-net-inv($white, rgba($white,.8), $white, $bg-net-background, $bg-net-inv, $bg-net-inv-svg, $bg-net-inv-top, $bg-net-inv-top-svg, $bg-net-inv-bottom, $bg-net-inv-bottom-svg);
}
.bg-net-inv-color-01{
	@include bg-net-inv($white, $white, mix($color-07,$white,90%), $color-01, $bg-net-inv-color-01-top, $bg-net-inv-color-01-top-svg, $bg-net-inv-color-01-top, $bg-net-inv-color-01-top-svg, $bg-net-inv-color-01-bottom, $bg-net-inv-color-01-bottom-svg);
}
.bg-net-inv-color-08{
	@include bg-net-inv($white, $white, $color-03, $color-08, $bg-net-inv-color-08-top, $bg-net-inv-color-08-top-svg, $bg-net-inv-color-08-top, $bg-net-inv-color-08-top-svg, $bg-net-inv-color-08-bottom, $bg-net-inv-color-08-bottom-svg);
}


.bg-promo {
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-image: $bg-dashed;
	background-size: 100% auto;
	padding: 84% 0 0;

	.img-group {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.promo-last {
	position: relative;
	top: 1.5rem;
	margin-top: -1.5rem;

	@include media-breakpoint-up(lg) {
		top: 4rem;
		margin-top: -4rem;
	}

	@include media-breakpoint-up(xxl) {
		top: 5.6rem;
		margin-top: -5.6rem;
	}
}


@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		.bg#{$infix}-top { background-position: top; }
		.bg#{$infix}-left { background-position: left; }
		.bg#{$infix}-right { background-position: right; }
		.bg#{$infix}-bottom { background-position: bottom; }
		.bg#{$infix}-none { background: none !important; }
		.bg#{$infix}-inherit { background: inherit !important; }
	}
}