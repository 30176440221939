html, body {
    max-width: 100%;
    overflow-x: hidden;
}

// Font sizes
.display-0 {
	font-size: $display0-size;
	font-weight: $font-weight-normal;
}
.display-1,
.display-2,
.display-3,
.display-4,
.display-5 {
	font-weight: $font-weight-normal;
}
@include media-breakpoint-down(md) {
	h1, .h1 { font-size: $h1-font-size-sm; }
	h2, .h2 { font-size: $h2-font-size-sm; }
	h3, .h3 { font-size: $h3-font-size-sm; }
	h4, .h4 { font-size: $h4-font-size-sm; }
	h5, .h5 { font-size: $h5-font-size-sm; }
	h6, .h6 { font-size: $h6-font-size-sm; }
	.lead { font-size: $h6-font-size-sm; }
	.display-0 { font-size: $display0-size-sm; }
	.display-1 { font-size: $display1-size-sm; }
	.display-2 { font-size: $display2-size-sm; }
	.display-3 { font-size: $display3-size-sm; }
	.display-4 { font-size: $display4-size-sm; }
}


// Blockquotes
.blockquote {
	padding: ($spacer * 2) ($spacer * 3);
	font-style: italic;
	color: $body-bg;
	background: $primary;
	margin-bottom: $spacer;
	line-height: 1.8;
	font-size: $blockquote-font-size;

	&:not(.text-center)::before {
		font-style: normal;
		font-family: sans-serif;
		content: '\201d';
		display: block;
		color: rgba($body-bg,.5);
		font-size: $font-size-base * 7.5;
		line-height: 1;
		margin-bottom: -($font-size-base * 4);
	}

	a,
	a:hover{
		color: $body-bg;
	}
}

.blockquote-footer {
	font-style: normal;
	display: block;
	font-size: inherit;
	margin-top: $spacer;
	font-weight: $font-weight-bold;
	line-height: $line-height-base;
	color: $blockquote-small-color;

	&::before {
		content: '';
	}
}

.hover-reverse {
	text-decoration: $link-hover-decoration;
	&:hover {
		text-decoration: $link-decoration;
	}
}



.number-list {
	padding: 0;
	list-style-type: none;
	&>li {
		display: flex;
		margin: 0 0 3rem;
		&:not([class*=col-]) {
			padding: 0;
		}
	}
	.list-number {
		font-size: 6rem;
		line-height: .8;
		font-weight: $font-weight-light;
		color: $primary;
		min-width: 4rem;
	}
}