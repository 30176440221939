.badge-gray {
	color: $white;
	background: $gray-500;
}
a.badge-gray {
	text-decoration: none;
	&:hover {
		color: $white;
		background: $gray-600;
	}
}