html.modal-on,
html.modal-on body {
	overflow: hidden;
}

.modal-open .modal {
	overflow-y: scroll; // ios bug
	-webkit-overflow-scrolling: touch; // ios bug
}

.modal-full {
	padding-right: 0 !important;
	.modal-dialog {
		max-width: 100%;
	}
}

.modal {
	&-content,
	&-header {
		border-radius: 0;
	}
	&-search,
	&-mainmenu,
	&-filter {
		.modal {
			&-content {
				color: #fff;
				background: $primary;
				a:not([class]),
				.nav-link {
					color: #fff;
				}
			}
			&-header,
			&-footer {
				border-color: rgba($body-bg, .2);
			}
		}
		.close {
			color: $body-bg;
		}
	}
	&-mainmenu {
		.modal {
			&-body {
				padding: 0;
			}
			&-dialog {
				margin: 0 0 0 auto;
			}
		}
		.close {
			font-size: $font-size-base;
		}

		.nav-item {
			overflow: hidden;
			&:first-child {
				border-top: 1px solid rgba($body-bg, .2);
			}
		}


		.nav-link {
			float: left;
			width: 100%;
			display: flex;
			align-items: center;
			padding-top: .85rem;
			padding-bottom: .85rem;
			border-bottom: 1px solid rgba($body-bg, .2);
			&:focus,
			&:hover,
			&.active {
				background: rgba($body-bg,.2);
			}
			.icon {
				margin-right: .5rem;
			}
		}

		.dropdown {
			&-menu {
				position: static!important;
				transform: none!important;
				background: none;
				border: none;
				float: left;
				width: 100%;
			}
			&-toggle {
				&:after {
					content: $icon-arrow-long-down;
					margin-left: auto;
				}
			}
			&.show {
				background: rgba($black, .1);
				.dropdown-toggle {
					&:after {
						content: $icon-arrow-long-up;
					}
				}
			}
			&-item {
				padding-top: .4rem; 
				padding-bottom: .4rem;
				color: $body-bg;
				&:focus,
				&:hover,
				&.active {
					background: rgba($body-bg,.2);
				}
			}
		}
	}
	&-search {
		.form-control,
		.btn {
			padding-left: 0;
			padding-right: 0;
			border-radius: 0;
			background: transparent;
			color: $body-bg;
			border-color: transparent;
			border-bottom-color: $body-bg;
		}
		.form-control {
			&::placeholder {
				color: rgba($body-bg,.75);
			}
		}
		.custom-control-label {
			&:before {
				background: $primary;
				box-shadow: 0 0 0 1px $body-bg;
			}
		}
	}
}