.calendar {
	&-item {
		display: flex;
	}
	&-link {
		display: block;
		width: 100%;
		position: relative;
		color: $white;
		text-decoration: none;
		background: $light;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $primary;
			transition: 0.1s;
		}
		&:hover {
			color: $white;
			text-decoration: none;
			&::before {
				top: -.5rem;
				left: -.5rem;
				right: -.5rem;
				bottom: -.5rem;
			}
		}
		.calendar-date {
			position: absolute;
			z-index: 1;
			top: $spacer;
			left: $spacer;
		}
	}
	&-figure {
		position: relative;
		margin: 0;
	}
	&-figcaption {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: $spacer/2 $spacer*2;
		background: $primary;
		min-height: 6.25rem;
		@include media-breakpoint-up(xl) {
			bottom: $spacer;
			left: $spacer;
			right: $spacer;
			position: absolute;
		}
		&:after {
			font-family: 'icons';
			padding: 0 0 0 $spacer;
			content: $icon-arrow-long-right;
		}
	}
	&-figcaption-full {
		position: relative;
		padding: $spacer/2 $spacer*2;
		background: $primary;
		@include media-breakpoint-up(xxl) {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			min-height: 10rem;
			bottom: 0;
			left: 0;
			right: 0;
			position: absolute;
			padding: $spacer*1.5 $spacer*2 $spacer*1.75;
			clip-path: polygon(-0.5% -0.5%, -0.5% 100.5%, 100.5% 100.5%, 100.5% 50.5%);
		}
		.calendar-title {
			font-weight: $font-weight-bold;
		}
	}
	&-content {
		line-height: $headings-line-height;

		:not(.active-calendar-list-item).rejected-calendar-list-item & {
          	&,
			& a.text-dark{
				text-decoration: line-through;
				color: $gray-500 !important;
			}

        }

        :not(.active-calendar-list-item).new-calendar-list-item &{
			&,
			& a.text-dark{
				color: $calendar-list-item-action-required-color !important;
			}
        }

		a {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		&-title {
			margin: 0 0 3px;
			font-size: 1.25rem;
			line-height: 1.2;
		}
		time {
			font-size: 1rem;
			display: block;
			margin-bottom: 6px;
		}
		p {
			margin: 0 0 9px;
			font-size: 1.25rem;
		}
		nav {
			font-size: 1rem;
		}
	}
	&-title {
		font-weight: $font-weight-normal;
		font-size: $font-size-base*1.132;
		margin: 0;
		line-height: $headings-line-height;
	}
	&-nav {
		display: block;
		font-size: $font-size-base*.9;
		white-space: nowrap;
		text-align: right;
		&:after {
			font-family: 'icons';
			padding: 0 0 0 $spacer/2;
			content: $icon-arrow-long-right;
		}
	}
	&-list-item {
		display: flex;
		margin-bottom: 1rem;
		.calendar-content {
			padding-left: $spacer;
		}
	}
}

.past-calendar-list-item{
	opacity: 0.8;
}

.calendar-date, a.calendar-date{
	&, &:hover{
		text-decoration: none;
	}
}

.date-items {
	width: 4.125rem;
	.date-item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		height: 4.125rem;
		font-size: 1.333rem;
		line-height: 1;
		color: $body-bg;
		background: $primary;

		.past-calendar-list-item &{
			background: mix($gray-400,$primary,85%);
		}

		.rejected-calendar-list-item &,
		.rejected-calendar-list-item.past-calendar-list-item &{
			background: mix($gray-400,$calendar-list-item-action-required-color,85%);
		}

		.new-calendar-list-item &{
			background: $calendar-list-item-action-required-color;
		}
		.past-calendar-list-item.new-calendar-list-item &{
			background: mix($calendar-list-item-action-required-color,$primary,85%);
		}

		.active-calendar-list-item &{
			background: $primary;
		}
		.past-calendar-list-item.active-calendar-list-item &{
			background: mix($gray-400,$primary,85%);
		}

		small {
			display: block;
			font-size: 0.9rem;
		}
	}
	.date-year {
		font-size: 1rem;
	}
	.date-separator {
		position: relative;
		margin-top: 0.556rem;
		&:before {
			content: '';
			width: 2px;
			height: 0.556rem;
			position: absolute;
			bottom: 100%;
			left: 50%;
			margin-left: -1px;
			background: $primary;
			.past-calendar-list-item &{
				background: mix($gray-400,$primary,85%);
			}
		}
	}
	.date-item-secondary {
		background: rgba($primary,.55);
		&:before {
			background: rgba($primary,.55);
		}
		.past-calendar-list-item &{
			background: rgba($gray-400,.55);
			&:before {
				background: rgba($gray-400,.55);
			}
		}
	}
}




@each $color, $value in $theme-colors {
	.calendar-#{$color} { 
		.date-item {
			background: $value;
		}
		.date-item-secondary {
			background: lighten($value,15%);
			&::before {
				background: lighten($value,15%);
			}
		}
		.calendar-figcaption,
		.calendar-figcaption-full {
			background: $value;
		}
		.calendar-link {
			&::before {
				background: $value;
			}
		}
	}
}