// --- BACKGROUND NET ---
$bg-net:     '../img/bg/net-01.png' !default;
$bg-net-inv: '../img/bg/net-inv-01.png' !default;
$bg-net-inv-top: '../img/bg/net-inv-top-01.png' !default;
$bg-net-inv-bottom: '../img/bg/net-inv-bottom-01.png' !default;

$bg-net-svg: null !default;
$bg-net-inv-svg: null !default;
$bg-net-inv-top-svg: null !default;
$bg-net-inv-bottom-svg: null !default;

$bg-net-inv-color-01-top-svg: null !default;
$bg-net-inv-color-01-bottom-svg: null !default;
$bg-net-inv-color-08-top-svg: null !default;
$bg-net-inv-color-08-bottom-svg: null !default;

// --- ICONS ---
@import "../icons/variables.scss";

// --- BOOTSTRAP CUSTOM VARIABLES ---
@import "bootstrap/variables";

// --- BOOTSTRAP ---
@import "../../node_modules/bootstrap/scss/bootstrap";

// --- CUSTOM ---
@import "components/reboot";
@import "components/type";
@import "components/nav";
@import "components/btn";
@import "components/badge";
@import "components/borders";
@import "components/breadcrumb";
@import "components/dropdown";
@import "components/modal";
@import "components/background-variant";
@import "components/caret";
@import "components/carousel";
@import "components/custom-forms";
@import "components/icons";
@import "components/layout";
@import "components/main-header";
@import "components/form-search";
@import "components/header-articles";
@import "components/hover-image";
@import "components/content-hover";
@import "components/calendar-items";
@import "components/images";
@import "components/footer";
@import "components/pagination";
@import "components/popover";
@import "components/sizing";
@import "components/tooltip";
@import "components/text";
@import "components/tables";
@import "components/container";
@import "components/forms";
@import "components/accordion";
@import "components/autocomplete";

// --- WORDPRESS ---
@import "components/wp";
@import "components/iframe";

// --- OWL CAROUSEL ---
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
.owl-carousel .owl-video-play-icon{
  background: url("../img/owl.video.play.png") no-repeat;
}

// --- JQUERY UI ---
@import "components/jquery-ui";