.icon {
	line-height: inherit;
	&:before {
		font-weight: normal;
		font-style: normal;
	}
	&.border {
		width: 2em;
		height: 2em;
		line-height: 2em;
		text-align: center;
	}
}